import React from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import { styled } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../data/store';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
	asyncCallDone,
	asyncCallStart,
} from '../../../data/stores/projectStore';
import { showAlert } from '../../../data/stores/alertStore';
import ScreenBusySpinner from '../../common/ScreenBusySpinner';
import Input from '../../common/form/Input';
import { AsyncStage } from '../../../data/reduxCommon';
import { createClient } from '../../../services/client-service';
import { ClientDTO } from '../../../data/models/client';
import ModalHeader from '../../common/ModalHeader';
import { closeModal } from '../../../data/stores/modalStore';
import useClientsList from '../../../hooks/useClientsList';
import Autocomplete from '../../common/form/AutoComplete';

export default function ClientCreationModal() {
	const { openId } = useSelector((state: RootState) => state.modalState);

	const open = openId === 'clientCreationModal';

	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const { dropdownOptions: clientsDropDownOptions } = useClientsList();

	const { stage } = useSelector((state: RootState) => state.clientState);

	const { handleSubmit, control, reset, formState } = useForm({
		defaultValues: {
			name: '',
		},
		mode: 'onSubmit',
	});
	const { errors } = formState;

	const resetForm = () => {
		reset();
	};

	const handleClose = () => {
		dispatch(closeModal());
		resetForm();
	};

	const handleCreateClient = async (data: ClientDTO) => {
		try {
			dispatch(asyncCallStart());
			const createdClient = await createClient(data);
			navigate(`/clients/${createdClient.id}`);
			handleClose();
		} catch (error) {
			console.error('error', error);
			dispatch(
				showAlert({
					message: 'An error occurred creating the client.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	return (
		<>
			<Modal open={open} handleClose={handleClose}>
				{open ? (
					<>
						<ScreenBusySpinner />
						<FormContainer>
							<ModalHeader>Create New Client</ModalHeader>
							<InputContainer>
								<Controller
									name={'name'}
									control={control}
									rules={{
										required: 'Name is required',
									}}
									render={({ field }) => (
										<Autocomplete
											{...field}
											id={'name'}
											label={'Name'}
											options={clientsDropDownOptions}
											filterOptions={(
												options,
												{ inputValue }
											) => {
												try {
													const match = new RegExp(
														inputValue,
														'i'
													);
													return options
														.filter((option) =>
															match.test(
																option.display
															)
														)
														.sort((a, b) => {
															if (
																a.display ===
																inputValue
															)
																return -1;
															if (
																b.display ===
																inputValue
															)
																return 1;
															return 0;
														});
												} catch (e) {
													// If regex fails, do basic string includes match
													return options.filter(
														(option) =>
															option.display
																.toLowerCase()
																.includes(
																	inputValue.toLowerCase()
																)
													);
												}
											}}
											onInputChange={(e, value) => {
												const regex = /[^A-Za-z0-9 -]/g;
												if (regex.test(value)) return;
												field.onChange(value);
											}}
											freeSolo
											error={!!errors.name}
											helperText={errors.name?.message}
										/>
									)}
								/>
								{/* <Controller
									name={'name'}
									control={control}
									rules={{ required: 'Name is required' }}
									render={({ field }) => (
										<Input
											{...field}
											label={'Client Name'}
											id="clientName"
											onChange={(e) => {
												const newValue = e.target.value;
												const regex = /[^A-Za-z0-9 -]/g;
												if (regex.test(newValue))
													return;
												field.onChange(newValue);
											}}
											error={errors.name}
											helperText={errors.name?.message}
										/>
									)}
								/> */}
								<SaveCancelButtons
									handleSave={handleSubmit(
										handleCreateClient
									)}
									handleCancel={handleClose}
									saveDisabled={stage === AsyncStage.Busy}
									cancelDisabled={stage === AsyncStage.Busy}
									saveText={'Create'}
								/>
							</InputContainer>
						</FormContainer>
					</>
				) : (
					<></>
				)}
			</Modal>
		</>
	);
}

const FormContainer = styled.div`
	text-align: center;
`;

const InputContainer = styled.div`
	max-width: 450px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(4)};
`;
