import React from 'react';
import { ClientStatuses } from '../../data/models/client';
import { styled } from 'styled-components';
import MuiChip from '@mui/material/Chip';
import { ActiveChip, ArchivedChip } from '../projects/ProjectStatusDisplay';

const ClientStatusDisplay = (props) => {
	const { status } = props;

	if (status === ClientStatuses.ACTIVE.id) {
		return <ActiveChip label={ClientStatuses.ACTIVE.display} />;
	}
	if (status === ClientStatuses.INACTIVE.id) {
		return <ArchivedChip label={ClientStatuses.INACTIVE.display} />;
	}

	return <span>{status}</span>;
};

export default ClientStatusDisplay;
