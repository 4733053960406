import { useState, useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { Client } from '../data/models/client';
import { fetchClients } from '../services/client-service';
import { useSelector } from 'react-redux';
import { RootState } from '../data/store';

const MAX_CLIENTS = 999;

function useClientsList() {
	const { astroToken } = useSelector((state: RootState) => state.authState);
	const [list, setList] = useState<Client[]>([]);

	useEffect(() => {
		const fetchClientsForDropdown = async () => {
			const clients = await fetchClients({
				searchText: '',
				order: 'created',
				direction: 'DESC',
				page: 0,
				take: MAX_CLIENTS,
				where: { status: 'ACTIVE' },
			});
			setList(clients?.list || []);
		};
		if (astroToken) {
			fetchClientsForDropdown();
		}
	}, [astroToken]);

	const dropdownOptions = useMemo(() => {
		return sortBy(
			list.map((client: any) => ({
				id: client.id,
				display: client.name,
			})),
			['display']
		);
	}, [list]);

	return {
		list,
		dropdownOptions,
	};
}

export default useClientsList;
