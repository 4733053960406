import { AxiosResponse } from 'axios';
import axiosClient from '../clients/axios-client';
import {
	Client,
	ClientContactDTO,
	ClientDTO,
	ClientUpdateDTO,
} from '../data/models/client';
import { stringifyReqObj } from '../utils/common-utils';

export async function createClient(data: ClientDTO): Promise<Client> {
	const graphqlQuery = `
            mutation {
                createClient(
                    data: ${stringifyReqObj(data)},
                ) {
                    ${clientROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.createClient;
}

export async function updateClient(data: ClientUpdateDTO): Promise<Client> {
	const graphqlQuery = `
            mutation {
                updateClient(
                    data: ${stringifyReqObj(data)},
                ) {
                    ${clientROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateClient;
}

export async function createClientContact(
	data: ClientContactDTO
): Promise<Client> {
	const graphqlQuery = `
            mutation {
                createClientContact(
                    data: ${stringifyReqObj(data)},
                ) {
                    ${clientROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.createClientContact;
}

export async function updateClientContact(
	data: ClientContactDTO
): Promise<Client> {
	const graphqlQuery = `
            mutation {
                updateClientContact(
                    data: ${stringifyReqObj(data)},
                ) {
                    ${clientROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateClientContact;
}

export async function fetchClient(id: string): Promise<Client> {
	const graphqlQuery = `
            {
                client(
                    id: "${id}",
                ) {
                    ${clientROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.client;
}

export const fetchClients = async (filter: object) => {
	const graphqlQuery = `
            {
                clients(filter: ${stringifyReqObj(filter)}) {
                    list { ${clientROGraphQl} }
                    count
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});

	return response?.data?.clients;
};

const clientROGraphQl = `
    id
	created
	updated
	createdBy {
		name
	}
    name
	status
	projects {
		id
		namespace
	}
	contacts {
		uuid
		firstName
		lastName
		title
		emailContacts {
			uuid
			value
			type
		}
		phoneContacts {
			uuid
			value
			type
		}
		created
	}
`;
