import React from 'react';
import { ProjectStatuses } from '../../data/models/project';
import { styled } from 'styled-components';
import MuiChip from '@mui/material/Chip';
import { Spinner } from '../common/BusySpinner';

const ProjectStatusDisplay = (props) => {
	const { status } = props;

	if (status === ProjectStatuses.ACTIVE.id) {
		return <ActiveChip label={ProjectStatuses.ACTIVE.display} />;
	}
	if (
		status === ProjectStatuses.UPDATING.id ||
		status === ProjectStatuses.INITIALIZING.id
	) {
		return (
			<BusyChip
				icon={
					<Spinner
						style={{ height: 15, width: 15, marginLeft: 10 }}
					/>
				}
				label={ProjectStatuses[status].display}
			/>
		);
	}
	if (status === ProjectStatuses.ARCHIVED.id) {
		return <ArchivedChip label={ProjectStatuses[status].display} />;
	}

	return <span>{status}</span>;
};

export default ProjectStatusDisplay;

export const ActiveChip = styled(MuiChip)`
	background-color: ${({ theme }) => theme.palette.success.dark} !important;
	border: 1px solid ${({ theme }) => theme.palette.success.main} !important;
	& .MuiChip-label {
		color: ${({ theme }) => theme.palette.success.main};
	}
	height: 24px !important;
`;

export const ArchivedChip = styled(MuiChip)`
	background-color: ${({ theme }) =>
		theme.palette.background.paper} !important;
	border: 1px solid ${({ theme }) => theme.palette.text.divider} !important;
	& .MuiChip-label {
		color: ${({ theme }) => theme.palette.text.primary};
	}
	height: 24px !important;
`;

const BusyChip = styled(MuiChip)`
	background-color: ${({ theme }) =>
		theme.palette.status.building.background} !important;
	& .MuiChip-label {
		color: ${({ theme }) => theme.palette.status.building.text};
	}
	height: 24px !important;
`;
