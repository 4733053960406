import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export function getTheme(mode: 'light' | 'dark') {
	let theme = createTheme({
		palette: {
			mode: mode,
			primary: {
				main: '#1f97f4',
			},
			secondary: {
				main: '#19857b',
			},
			success: {
				main: mode === 'light' ? '#31AD37' : '#67e56d',
				dark: mode === 'light' ? '#E9FDEA' : '#2a562b',
			},
			error: {
				main: '#f44336',
			},
			warning: {
				main: '#ff9800',
			},
			info: {
				main: mode === 'light' ? '#2196f3' : '#53A7F0',
				// @ts-ignore
				secondary: mode === 'light' ? '#d3ebfd' : '#53A7F0',
			},
			neutral: {
				main: '#E0E0E0',
				light: '#E7E7E7',
			},
			background: {
				default: mode === 'light' ? '#F9FAFD' : '#191b1d',
				paper: mode === 'light' ? '#fff' : '#1f2125',
			},
			text: {
				primary: mode === 'light' ? '#1E1E1E' : '#ffffff',
				secondary: mode === 'light' ? '#444746' : '#bbbbbb',
				// @ts-ignore
				label: mode === 'light' ? '#00000099' : '#9A9CA5',
				divider: mode === 'light' ? '#DCDCDC' : '#9A9CA5',
				subheader: mode === 'light' ? '#00000099' : '#ffffff',
			},
			action: {
				selected: mode === 'light' ? '#C2E7FF' : '#1f97f4',
				// @ts-ignore
				pending: mode === 'light' ? '#999999' : '#191b1d',
			},
			status: {
				building: {
					text: mode === 'light' ? '#00000099' : '#1f97f4',
					background: mode === 'light' ? '#d3ebfd' : '#1f384e',
				},
				queued: {
					text: mode === 'light' ? '#00000099' : '#545455',
					background: mode === 'light' ? '#E7E7E7' : '#E1E1E1',
				},
			},
		},
		typography: {
			fontFamily: 'Roboto, Arial, sans-serif',
			h1: {
				fontSize: '2.2rem',
				fontWeight: 500,
			},
			// Add more typography styles here
		},
		spacing: 8, // The base spacing unit
		borderRadius: 5, // The global border radius
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 960,
				lg: 1280,
				xl: 1920,
			},
		},
		components: {
			// Component style overrides go here
			MuiButton: {
				styleOverrides: {
					root: {
						// Define button styles here
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor:
							mode === 'light' ? '#F9FAFD' : '#191b1d',
						boxShadow: 'none',
						backgroundImage: 'none',
					},
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						backgroundColor:
							mode === 'light' ? '#F9FAFD' : '#191b1d',
						borderRight: 'none',
					},
				},
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						paddingRight: 10,
					},
				},
			},
			MuiListItemButton: {
				styleOverrides: {
					root: {
						paddingTop: 0,
						paddingBottom: 0,
						marginBottom: 10,
						marginLeft: 10,
						borderRadius: 50,
						color: mode === 'light' ? 'black' : '#ffffff',
					},
				},
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						color: mode === 'light' ? 'black' : '#ffffff',
					},
				},
			},
		},
		// Define additional custom properties here if needed
	});

	theme = responsiveFontSizes(theme); // Enhance the theme for responsive fonts

	return theme;
}
