import React, { useEffect, useMemo, useState } from 'react';
import ScreenContainer from '../../common/layout/ScreenContainer';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../data/store';
import { fetchClient } from '../../../data/thunks/clientThunk';
import ScreenBusySpinner from '../../common/ScreenBusySpinner';
import BusySpinner from '../../common/BusySpinner';
import dayjs from 'dayjs';
import Paper, { PaperHeader, PaperHeaderNav } from '../../common/layout/Paper';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { styled } from 'styled-components';
import ClientContactsTable from './ClientContactsTable';
import AddClientContactModal from './AddClientContactModal';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ClientStatuses } from '../../../data/models/client';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import Menu from '../../common/Menu';
import Dialog, {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '../../common/Dialog';
import Button from '@mui/material/Button';
import { updateClient } from '../../../services/client-service';
import { showAlert } from '../../../data/stores/alertStore';
import {
	asyncCallDone,
	asyncCallError,
	asyncCallStart,
	refreshClientSuccess,
} from '../../../data/stores/clientStore';
import { ActiveChip, ArchivedChip } from '../../projects/ProjectStatusDisplay';

export default function ClientDetailsScreen() {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const match = useMatch('clients/:clientId/*');
	const clientId = match?.params?.clientId;

	const { client } = useSelector((state: RootState) => state.clientState);

	const [archiveClientModal, setArchiveClientModal] = useState(false);
	const [unarchiveClientModal, setUnarchiveClientModal] = useState(false);

	useEffect(() => {
		if (clientId && clientId !== client.id) {
			dispatch(fetchClient(clientId));
		}
	}, [clientId, client.id, dispatch]);

	const handleUpdateClientStatus = async (newStatus: string) => {
		try {
			dispatch(asyncCallStart());
			const updatedClient = await updateClient({
				id: client.id,
				status: newStatus,
			});
			dispatch(refreshClientSuccess(updatedClient));
			dispatch(
				showAlert({
					message: `Successfully ${
						newStatus === ClientStatuses.ACTIVE.id
							? 'unarchived'
							: 'archived'
					} the client.`,
					severity: 'success',
				})
			);
		} catch (error) {
			dispatch(asyncCallError(error));
			dispatch(
				showAlert({
					message: 'An error occurred updating the client.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
		setArchiveClientModal(false);
		setUnarchiveClientModal(false);
	};

	const menuItems = useMemo(() => {
		const menuItems: any[] = [];
		if (client.status === ClientStatuses.ACTIVE.id) {
			menuItems.push({
				display: 'Archive Client',
				onClick: () => setArchiveClientModal(true),
				icon: (
					<ArchiveOutlinedIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			return menuItems;
		} else if (client.status === ClientStatuses.INACTIVE.id) {
			return [
				{
					display: 'Unarchive Client',
					onClick: () => setUnarchiveClientModal(true),
					icon: (
						<UnarchiveOutlinedIcon
							color={'primary'}
							fontSize={'medium'}
							sx={{ marginRight: 1 }}
						/>
					),
				},
			];
		} else {
			return [];
		}
	}, [client.status]);

	if (clientId !== client.id) {
		return <BusySpinner />;
	}

	return (
		<>
			<ScreenBusySpinner />
			<AddClientContactModal />
			<Dialog
				open={archiveClientModal}
				handleClose={() => setArchiveClientModal(false)}
			>
				<DialogTitle>Archive Client</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to archive this client? This will
						make the client inactive.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setArchiveClientModal(false)}>
						Cancel
					</Button>
					<Button
						onClick={() =>
							handleUpdateClientStatus(ClientStatuses.INACTIVE.id)
						}
						color="primary"
					>
						Archive
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={unarchiveClientModal}
				handleClose={() => setUnarchiveClientModal(false)}
			>
				<DialogTitle>Unarchive Client</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to unarchive this client? This
						will make the client active again.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setUnarchiveClientModal(false)}>
						Cancel
					</Button>
					<Button
						onClick={() =>
							handleUpdateClientStatus(ClientStatuses.ACTIVE.id)
						}
						color="primary"
					>
						Unarchive
					</Button>
				</DialogActions>
			</Dialog>
			<StyledContainer>
				<StyledPaper>
					<HeaderContainer>
						<PaperHeader>
							<PaperHeaderNav
								onClick={() => navigate('/clients')}
							>
								Clients <ChevronRightOutlinedIcon />
							</PaperHeaderNav>
							<ClientName>{client.name.toUpperCase()}</ClientName>
							{client.status === ClientStatuses.ACTIVE.id && (
								<ActiveChip
									label={ClientStatuses.ACTIVE.display}
								/>
							)}
							{client.status === ClientStatuses.INACTIVE.id && (
								<ArchivedChip
									label={ClientStatuses.INACTIVE.display}
								/>
							)}
						</PaperHeader>
						<Menu
							menuItems={menuItems}
							variant={'outlined'}
							color={'primary'}
							alternativeIcon={() => <SettingsOutlinedIcon />}
						/>
					</HeaderContainer>
					<div>
						Created: {dayjs(client.created).format('MMM D, YYYY')}
					</div>
					<div>Created by: {client.createdBy?.name}</div>
				</StyledPaper>
				<ClientContactsTable />
			</StyledContainer>
		</>
	);
}

const StyledPaper = styled(Paper)`
	padding: 9px 20px 20px 10px;
	margin-bottom: 20px;
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ClientName = styled.div`
	display: inline;
	margin-right: 20px;
	color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledContainer = styled.div`
	margin-right: auto;
	margin-left: auto;
	height: 100%;
`;
