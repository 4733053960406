import React, { useEffect, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BusySpinner from '../../common/BusySpinner';
import ScreenBusySpinner from '../../common/ScreenBusySpinner';
import { AppDispatch, RootState } from '../../../data/store';
import { fetchProject } from '../../../data/thunks/projectThunk';
import { fetchProject as fetchProjectService } from '../../../services/project-service';
import {
	ProjectCategories,
	ProjectStatuses,
} from '../../../data/models/project';
import MuiChip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { styled } from 'styled-components';
import Menu from '../../common/Menu';
import ProjectNameChangeModal from './ProjectNameChangeModal';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import ProjectArchiveModal from './ProjectArchiveModal';
import ProjectUnarchiveModal from './ProjectUnarchiveModal';
import BusinessIcon from '@mui/icons-material/Business';
import ProjectChangeClient from './ProjectChangeClientModal';
import { SubHeader } from '../../common/layout/PageHeader';
import { Divider } from '@mui/material';
import GreenCheckIcon from '../../common/GreenCheckIcon';
import RedExclamationIcon from '../../common/RedExclamationIcon';
import ProjectStatusDisplay from '../ProjectStatusDisplay';
import GoogleDriveIcon from '../../../images/google-drive.png';
import GoogleChatIcon from '../../../images/google-chat.png';
import Paper, { PaperHeader, PaperHeaderNav } from '../../common/layout/Paper';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ProjectTypeDisplay from '../ProjectTypeDisplay';
import ProjectChangeTypeModal from './ProjectChangeTypeModal';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import { FlexCenterRow } from '../../common/layout/Content';
import Tabs from '../../common/Tabs';
import { getIconFromGoogleMimeType } from '../../../utils/company-utils';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ProjectAddTemplateModal from './ProjectAddTemplateModal';
import { refreshProjectSuccess } from '../../../data/stores/projectStore';

export default function ProjectDetailsScreen() {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const match = useMatch('projects/:projectId/*');
	const projectId = match?.params?.projectId;

	const [projectNameChangeModalOpen, setProjectNameChangeModalOpen] =
		useState(false);
	const [archiveProjectModal, setArchiveProjectModal] = useState(false);
	const [unarchiveProjectModal, setUnarchiveProjectModal] = useState(false);
	const [changeClientModal, setChangeClientModal] = useState(false);
	const [changeTypeModal, setChangeTypeModal] = useState(false);
	const [addTemplateModal, setAddTemplateModal] = useState(false);

	const { project } = useSelector((state: RootState) => state.projectState);

	useEffect(() => {
		if (projectId && projectId !== project.id) {
			dispatch(fetchProject(projectId));
		}
	}, [projectId, dispatch, project.id]);

	useEffect(() => {
		const checkStatuses = () => {
			if (
				project.status === ProjectStatuses.INITIALIZING.id ||
				project.status === ProjectStatuses.UPDATING.id
			) {
				return true;
			}

			const fileStatuses = project.projectInitializationFileStatuses;
			if (
				!fileStatuses ||
				project.projectInitializationStatus?.googleChatSpaceStatus ===
					'INITIALIZING'
			) {
				return true;
			}
			return Object.values(fileStatuses).some(
				(fileStatus: any) =>
					fileStatus.status === 'CREATED' ||
					fileStatus.status === 'INITIALIZING'
			);
		};

		if (checkStatuses() && projectId) {
			const interval = setInterval(async () => {
				const updatedProject = await fetchProjectService(projectId);
				dispatch(refreshProjectSuccess(updatedProject));
			}, 2000);

			return () => {
				clearInterval(interval);
			};
		}
	}, [
		project.status,
		projectId,
		dispatch,
		project.projectInitializationFileStatuses,
		project.projectInitializationStatus?.googleChatSpaceStatus,
	]);

	const menuItems = useMemo(() => {
		if (project.status === ProjectStatuses.ACTIVE.id) {
			const menuItems: any[] = [];
			menuItems.push({
				display: 'Update Project Name',
				onClick: () => setProjectNameChangeModalOpen(true),
				// disabled:
				// 	!project.projectInitializationStatus.googleFilesUpdated &&
				// 	dayjs()
				// 		.subtract(24, 'hour') // is less than 24 hours
				// 		.isBefore(dayjs(project.created)),
				icon: (
					<DriveFileRenameOutlineOutlinedIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			menuItems.push({
				display: 'Archive Project',
				onClick: () => setArchiveProjectModal(true),
				icon: (
					<ArchiveOutlinedIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			menuItems.push({
				display: 'Change Client',
				onClick: () => setChangeClientModal(true),
				icon: (
					<BusinessIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			menuItems.push({
				display: 'Change Project Type',
				onClick: () => setChangeTypeModal(true),
				icon: (
					<CategoryOutlinedIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			menuItems.push({
				display: 'View Inactive Templates',
				onClick: () => setAddTemplateModal(true),
				icon: (
					<VisibilityOffOutlinedIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			return menuItems;
		} else if (project.status === ProjectStatuses.ARCHIVED.id) {
			return [
				{
					display: 'Unarchive Project',
					onClick: () => setUnarchiveProjectModal(true),
					icon: (
						<ArchiveOutlinedIcon
							color={'primary'}
							fontSize={'medium'}
							sx={{ marginRight: 1 }}
						/>
					),
				},
			];
		} else {
			return [];
		}
	}, [
		project.status,
		project.projectInitializationStatus.googleFilesUpdated,
		project.created,
	]);

	if (projectId !== project.id) {
		return <BusySpinner />;
	}

	return (
		<>
			<ScreenBusySpinner />
			<ProjectNameChangeModal
				project={project}
				open={projectNameChangeModalOpen}
				handleClose={() => setProjectNameChangeModalOpen(false)}
			/>
			<ProjectArchiveModal
				project={project}
				open={archiveProjectModal}
				handleClose={() => setArchiveProjectModal(false)}
			/>
			<ProjectUnarchiveModal
				project={project}
				open={unarchiveProjectModal}
				handleClose={() => setUnarchiveProjectModal(false)}
			/>
			<ProjectChangeClient
				project={project}
				open={changeClientModal}
				handleClose={() => setChangeClientModal(false)}
			/>
			<ProjectChangeTypeModal
				project={project}
				open={changeTypeModal}
				handleClose={() => setChangeTypeModal(false)}
			/>
			<ProjectAddTemplateModal
				open={addTemplateModal}
				handleClose={() => setAddTemplateModal(false)}
			/>
			<ScreenContainer>
				<HeaderContainer>
					<NamespaceContainer>
						<PaperHeader>
							<PaperHeaderNav
								onClick={() => navigate('/projects')}
							>
								Projects <ChevronRightOutlinedIcon />
							</PaperHeaderNav>
							<Namespace>{project.namespace}</Namespace>
						</PaperHeader>
						<Menu
							menuItems={menuItems}
							variant={'outlined'}
							color={'primary'}
							alternativeIcon={() => <SettingsOutlinedIcon />}
						/>
					</NamespaceContainer>
					<SubHeader>
						<ProjectStatusDisplay status={project.status} />
						<span style={{ marginLeft: 10 }}></span>
						<ProjectTypeDisplay type={project.type} />
					</SubHeader>
					<ClientName>{project.client.name}</ClientName>
					<SubHeader>
						Created:{' '}
						{dayjs(project.created).format('MMM D, YYYY | h:mm A')}
					</SubHeader>
					<BuildingStatus project={project} />
					<IconContainer>
						<IconBox>
							<DriveStatus
								status={
									project.projectInitializationStatus
										.googleChatSpaceStatus
								}
							/>
							<IconTextContainer>
								<IconText>Chat Space</IconText>
								<img
									style={{
										height: 20,
										marginLeft: 7,
										objectFit: 'cover',
										cursor: 'pointer',
									}}
									src={GoogleChatIcon}
									alt={'Google Drive'}
									onClick={() =>
										window.open(
											`https://mail.google.com/chat/u/2/#chat/space/${project.chatSpaceId}`,
											'_blank'
										)
									}
								/>
							</IconTextContainer>
						</IconBox>
						<IconBox>
							<DriveStatus
								status={
									project.projectInitializationStatus
										.googleDriveStatus
								}
							/>
							<IconTextContainer>
								<IconText>Drive</IconText>
								<img
									style={{
										height: 20,
										marginLeft: 7,
										objectFit: 'cover',
										cursor: 'pointer',
									}}
									src={GoogleDriveIcon}
									alt={'Google Drive'}
									onClick={() =>
										window.open(
											`https://drive.google.com/drive/u/${project.driveId}/folders/${project.folderId}`,
											'_blank'
										)
									}
								/>
							</IconTextContainer>
						</IconBox>
					</IconContainer>
				</HeaderContainer>
				{/* <FileStatuses project={project} /> */}
				<TemplatesContainer />
			</ScreenContainer>
		</>
	);
}

const BuildingStatus = (props) => {
	const { project } = props;

	if (!project.chatSpaceId) {
		return (
			<BuildingStatusText className="fade-in">
				Building Chat Space...
			</BuildingStatusText>
		);
	}
	if (
		project.projectInitializationStatus.googleDriveStatus === 'INITIALIZING'
	) {
		return (
			<BuildingStatusText className="fade-in">
				Building Project Folders...
			</BuildingStatusText>
		);
	}
	if (
		project.projectInitializationFileStatuses[
			ProjectCategories.LEGAL_AND_CONTRACT.id
		].status === 'INITIALIZING'
	) {
		return (
			<BuildingStatusText className="fade-in">
				Building Legal Templates...
			</BuildingStatusText>
		);
	}
	if (
		project.projectInitializationFileStatuses[ProjectCategories.CREATIVE.id]
			.status === 'INITIALIZING'
	) {
		return (
			<BuildingStatusText className="fade-in">
				Building Creative Templates...
			</BuildingStatusText>
		);
	}
	if (
		project.projectInitializationFileStatuses[
			ProjectCategories.PRODUCTION.id
		].status === 'INITIALIZING'
	) {
		return (
			<BuildingStatusText className="fade-in">
				Building Production Templates...
			</BuildingStatusText>
		);
	}
	if (
		project.projectInitializationFileStatuses[
			ProjectCategories.POST_PRODUCTION.id
		].status === 'INITIALIZING'
	) {
		return (
			<BuildingStatusText className="fade-in">
				Building Post-Production Templates...
			</BuildingStatusText>
		);
	}
	return null;
};

const BuildingStatusText = styled.div`
	font-weight: 500;
	font-size: 16px;
	margin-top: 10px;
	opacity: 0;
	animation: fadeIn 0.2s ease-in forwards;

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const TemplatesContainer = (props) => {
	const {
		project: { projectInitializationFileStatuses },
	} = useSelector((state: RootState) => state.projectState);

	const [selectedPanel, setSelectedPanel] = useState(
		ProjectCategories.LEGAL_AND_CONTRACT.id
	);

	const panels = [
		{
			label: (
				<FlexCenterRow>
					Legal Templates{' '}
					{projectInitializationFileStatuses[
						ProjectCategories.LEGAL_AND_CONTRACT.id
					].status === 'SUCCESS' ? (
						<StyledGreenCheckIcon />
					) : (
						<StyledScheduleIcon />
					)}
				</FlexCenterRow>
			),
			component: (
				<TemplatesList
					category={ProjectCategories.LEGAL_AND_CONTRACT.display}
					templates={Object.values(
						projectInitializationFileStatuses[
							ProjectCategories.LEGAL_AND_CONTRACT.id
						]?.list || {}
					).map((t) => t)}
				/>
			),
			onclick: () =>
				setSelectedPanel(ProjectCategories.LEGAL_AND_CONTRACT.id),
			key: ProjectCategories.LEGAL_AND_CONTRACT.id,
		},
		{
			label: (
				<FlexCenterRow>
					Creative Templates{' '}
					{projectInitializationFileStatuses[
						ProjectCategories.CREATIVE.id
					].status === 'SUCCESS' ? (
						<StyledGreenCheckIcon />
					) : (
						<StyledScheduleIcon />
					)}
				</FlexCenterRow>
			),
			component: (
				<TemplatesList
					category={ProjectCategories.CREATIVE.display}
					templates={Object.values(
						projectInitializationFileStatuses[
							ProjectCategories.CREATIVE.id
						]?.list || {}
					).map((t) => t)}
				/>
			),
			onclick: () => setSelectedPanel(ProjectCategories.CREATIVE.id),
			key: ProjectCategories.CREATIVE.id,
		},
		{
			label: (
				<FlexCenterRow>
					Production Templates{' '}
					{projectInitializationFileStatuses[
						ProjectCategories.PRODUCTION.id
					].status === 'SUCCESS' ? (
						<StyledGreenCheckIcon />
					) : (
						<StyledScheduleIcon />
					)}
				</FlexCenterRow>
			),
			component: (
				<TemplatesList
					category={ProjectCategories.PRODUCTION.display}
					templates={Object.values(
						projectInitializationFileStatuses[
							ProjectCategories.PRODUCTION.id
						]?.list || {}
					).map((t) => t)}
				/>
			),
			onclick: () => setSelectedPanel(ProjectCategories.PRODUCTION.id),
			key: ProjectCategories.PRODUCTION.id,
		},
		{
			label: (
				<FlexCenterRow>
					Post-Production Templates{' '}
					{projectInitializationFileStatuses[
						ProjectCategories.POST_PRODUCTION.id
					].status === 'SUCCESS' ? (
						<StyledGreenCheckIcon />
					) : (
						<StyledScheduleIcon />
					)}
				</FlexCenterRow>
			),
			component: (
				<TemplatesList
					category={ProjectCategories.POST_PRODUCTION.display}
					templates={Object.values(
						projectInitializationFileStatuses[
							ProjectCategories.POST_PRODUCTION.id
						]?.list || {}
					).map((t) => t)}
				/>
			),
			onclick: () =>
				setSelectedPanel(ProjectCategories.POST_PRODUCTION.id),
			key: ProjectCategories.POST_PRODUCTION.id,
		},
	];

	return (
		<TemplateSelectorContainer>
			<Tabs panels={panels} currentPath={selectedPanel} />
		</TemplateSelectorContainer>
	);
};

const TemplatesList = (props) => {
	const { templates, category } = props;

	if (!templates.length) {
		return (
			<NoTemplatesTextContainer>
				No {category} Templates currently exist for this project.
			</NoTemplatesTextContainer>
		);
	}

	return (
		<div>
			{templates.map((t) => (
				<TemplateRow key={t.templateName} template={t} />
			))}
		</div>
	);
};

const NoTemplatesTextContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
`;

const TemplateRow = (props) => {
	const { template } = props;
	const icon = getIconFromGoogleMimeType(template.mimeType);

	return (
		<TemplateRowContainer>
			<FlexCenterRow>
				<img
					style={{
						height: 20,
						marginRight: 10,
						objectFit: 'cover',
					}}
					src={icon.icon}
					alt={icon.alt}
				/>
				{template.templateName}
			</FlexCenterRow>
			<FlexCenterRow>
				<StatusIconContainer>
					{template.status === 'SUCCESS' ? (
						<StyledGreenCheckIcon className="fade-in" />
					) : (
						<StyledScheduleIcon className="fade-in" />
					)}
				</StatusIconContainer>
			</FlexCenterRow>
		</TemplateRowContainer>
	);
};

const StatusIconContainer = styled.div`
	.fade-in {
		opacity: 0;
		animation: fadeIn 0.2s ease-in forwards;
	}
`;

const StyledGreenCheckIcon = styled(GreenCheckIcon)`
	font-size: 20px !important;
	margin-left: 5px;
`;

const StyledScheduleIcon = styled(ScheduleIcon)`
	color: ${({ theme }) => theme.palette.action.pending};
	font-size: 20px !important;
	margin-left: 5px;
`;

const TemplateSelectorContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 10px;
`;

const TemplateRowContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid ${({ theme }) => theme.palette.text.divider};
`;

const DriveStatus = (props) => {
	const { status } = props;

	return (
		<ChipContainer>
			{status === 'INITIALIZING' ? (
				<QueuedChip
					className="fade-in"
					label={
						<FlexCenterRow>
							<StyledScheduleIcon /> Queued
						</FlexCenterRow>
					}
				/>
			) : status === 'SUCCESS' || status === 'CREATED' ? (
				<div className="fade-in">
					<GreenCheckIcon />
				</div>
			) : (
				<BuildingChip
					className="fade-in"
					label={
						<FlexCenterRow>
							<StyledCachedIcon className="spin" /> Building
						</FlexCenterRow>
					}
				/>
			)}
		</ChipContainer>
	);
};

const ChipContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 15px;
	margin-right: 15px;
	font-style: italic;

	.fade-in {
		opacity: 0;
		animation: fadeIn 0.2s ease-in forwards;
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const StyledCachedIcon = styled(CachedIcon)`
	margin-right: 5px;
	color: ${({ theme }) => theme.palette.primary.main};

	&.spin {
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

const QueuedChip = styled(MuiChip)`
	background-color: ${({ theme }) =>
		theme.palette.status.queued.background} !important;
	& .MuiChip-label {
		color: ${({ theme }) => theme.palette.status.queued.text} !important;
	}
	height: 24px !important;
	width: 100px;
`;

const BuildingChip = styled(MuiChip)`
	background-color: ${({ theme }) =>
		theme.palette.status.building.background} !important;
	& .MuiChip-label {
		color: ${({ theme }) => theme.palette.status.building.text} !important;
	}
	height: 35px !important;
	width: 100px;
	border-radius: 20px !important;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 5px;
`;

const IconBox = styled.div`
	border: 2px solid ${({ theme }) => theme.palette.info.main};
	width: 225px;
	height: 120px;
	border-radius: 5px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-right: 15px;
`;

const IconTextContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
`;

const IconText = styled.div`
	font-size: 26px;
	font-weight: 500;
`;

const ScreenContainer = styled(Paper)`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const Namespace = styled.div`
	display: inline;
	margin-right: 20px;
	color: ${({ theme }) => theme.palette.text.primary};
`;

const HeaderContainer = styled.div`
	padding: 10px 10px 20px 10px !important;
	display: flex;
	flex-direction: column;
	// border-bottom: 1px solid ${({ theme }) => theme.palette.text.divider};
	margin-bottom: 5px;
`;

const NamespaceContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ClientName = styled.div`
	font-size: 18px;
	margin-top: 5px;
	font-weight: 500;
`;

const FileStatus = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	align-items: center;
`;

const Key = styled.span``;

const Value = styled.span`
	align-items: center;
	display: flex;
	margin-left: 1em;
`;

const FileStatuses = (props: any) => {
	const {
		project: {
			projectInitializationFileStatuses,
			projectInitializationStatus,
			projectUpdateStatus,
			status,
		},
	} = props;
	if (!projectInitializationFileStatuses) {
		return <div>Getting files ready for creation</div>;
	}

	if (status === 'UPDATING') {
		return <div>{projectUpdateStatus?.description}</div>;
	}

	let hasPendingStatuses =
		projectInitializationStatus.googleChatSpaceStatus === 'INITIALIZING';
	let hasErrorStatuses =
		projectInitializationStatus.googleChatSpaceStatus === 'ERROR';
	for (const fileStatus of Object.values(
		projectInitializationFileStatuses
	) as any[]) {
		if (
			fileStatus.status === 'INITIALIZING' ||
			fileStatus.status === 'CREATED'
		) {
			hasPendingStatuses = true;
		}
		if (fileStatus.status === 'UPDATE_FAILED') {
			hasErrorStatuses = true;
		}
	}

	const keys = Object.keys(projectInitializationFileStatuses);
	// const longestKeyLength = Math.max(...keys.map((key) => key.length));

	return (
		<FileStatusContainer>
			<CenterContainer>
				<FileStatus>
					<Key>ChatSpace</Key>
					<Value>
						{getFileStatus(
							projectInitializationStatus.googleChatSpaceStatus
						)}
					</Value>
				</FileStatus>
				{keys.map((key) => (
					<>
						<Divider style={{ marginTop: 8, marginBottom: 8 }} />
						<FileStatus key={key}>
							<Key>{key}</Key>
							<Value>
								{getFileStatus(
									projectInitializationFileStatuses[key]
										.status
								)}
							</Value>
						</FileStatus>
					</>
				))}
			</CenterContainer>
		</FileStatusContainer>
	);
};

const FileStatusContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex-grow: 1;
`;

const CenterContainer = styled.div`
	display: flex;
	justify-content: left;
	flex-direction: column;
	width: 440px;
	max-width: 100%;
	overflow: scroll;
	padding-left: 15px;
	padding-right: 15px;
`;

const getFileStatus = (status: string) => {
	switch (status) {
		case 'CREATED':
			return 'Created';
		case 'INITIALIZING':
			return 'Initializing';
		case 'ERROR':
			return <RedExclamationIcon />;
		case 'CREATION_ERROR':
			return <RedExclamationIcon />;
		case 'UPDATE_ERROR':
			return <RedExclamationIcon />;
		case 'SUCCESS':
			return <GreenCheckIcon />;
		default:
			return status;
	}
};
