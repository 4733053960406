import React, { useEffect, useMemo } from 'react';
import usePagination from '../../hooks/usePaginationStore';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { fetchClients } from '../../data/thunks/clientThunk';
import { updateClientsFilter } from '../../data/stores/clientStore';
import dayjs from 'dayjs';
import Table, { TableFilterOption } from '../common/Table';
import {
	GridColDef,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid';
import Link from '../common/Link';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import {
	HeaderButton,
	HeaderContainer,
	HeaderTitle,
} from '../common/layout/PageHeader';
import { clientStatusOptions } from '../../data/models/client';
import ClientStatusDisplay from './ClientStatusDisplay';

export default function ClientsTable() {
	const dispatch: AppDispatch = useDispatch();
	const navigate = useNavigate();

	const { clients } = useSelector((state: RootState) => state.clientState);

	const {
		searchText,
		setSearchText,
		page,
		setPage,
		pageSize,
		setPageSize,
		sortModel,
		setSortModel,
		where,
		setWhere,
	} = usePagination('clientState', 'clients', updateClientsFilter);

	useEffect(() => {
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchClients(filter));
	}, []);

	const handleTextSearch = (newSearchText: string) => {
		setSearchText(newSearchText);
		setPage(0);
		const filter = {
			searchText: newSearchText,
			order: 'created',
			direction: 'DESC',
			page: 0,
			take: pageSize,
		};
		dispatch(fetchClients(filter));
	};

	const handleSortOnChange = (order) => {
		setSortModel(order);
		const filter = {
			searchText,
			order: order[0]?.field,
			direction: order[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchClients(filter));
	};

	const handleOnPageChange = (paginationModel, scrollToTop = false) => {
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: paginationModel.page,
			take: pageSize,
			where,
		};
		setPage(paginationModel.page);
		dispatch(fetchClients(filter));
		if (scrollToTop) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const handleOnPageSizeChange = (pageSize) => {
		setPageSize(pageSize);
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchClients(filter));
	};

	const handleFilterOnChange = (field, value) => {
		const newWhere = { ...where };
		if (!!value?.id || !!value) {
			newWhere[field] = value?.id || value;
		} else {
			delete newWhere[field];
		}
		setWhere(newWhere);
		setPage(0);
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: 0,
			take: pageSize,
			where: newWhere,
		};
		dispatch(fetchClients(filter));
	};

	const clientStatuses = clientStatusOptions();
	const filterOptions: TableFilterOption[] = [
		{
			field: 'status',
			label: 'Status',
			options: clientStatuses.map((status) => ({
				id: status.id,
				display: status.display,
			})),
			value: where.status,
			onChange: handleFilterOnChange,
		},
	];

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 200,
				disableColumnMenu: true,
				renderCell: (params: any) => {
					return (
						<Link
							onClick={() =>
								navigate(`/clients/${params.row.id}`)
							}
						>
							{params.value}
						</Link>
					);
				},
			},
			{
				field: 'status',
				headerName: 'Status',
				minWidth: 150,
				disableColumnMenu: true,
				renderCell: (params: any) => (
					<ClientStatusDisplay status={params.value} />
				),
			},
			{
				field: 'projects',
				headerName: 'Projects',
				minWidth: 200,
				disableColumnMenu: true,
				renderCell: (params: any) => <div>{params.value}</div>,
			},
			{
				field: 'created',
				headerName: 'Created',
				minWidth: 120,
				disableColumnMenu: true,
				renderCell: (params: any) =>
					dayjs(params.value).format('MMM D, YYYY'),
			},
		];
	}, []);

	const rows: any[] = useMemo(() => {
		return clients.list.map((client) => ({
			id: client.id,
			name: client.name,
			status: client.status,
			projects: client.projects.length,
			created: client.created,
		}));
	}, [clients.list]);

	return (
		<Table
			title={'Clients'}
			columns={columns}
			rows={rows}
			page={page}
			pageSize={pageSize}
			rowCount={clients.count}
			sortModel={sortModel}
			onSortModelChange={handleSortOnChange}
			searchText={searchText}
			handleTextSearch={handleTextSearch}
			onPaginationModelChange={handleOnPageChange}
			filterOptions={filterOptions}
			loading={!clients.initialized}
		/>
	);
}
