import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../common/Modal';
import SaveCancelButtons from '../common/buttons/SaveCancelButtons';
import { styled } from 'styled-components';
import ModalHeader from '../common/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { closeModal } from '../../data/stores/modalStore';
import Input from '../common/form/Input';
import { Controller, useController, useForm } from 'react-hook-form';
import { budgetRangeOptions } from '../../data/models/project';
import Autocomplete from '../common/form/AutoComplete';
import { Grid } from '../common/layout/Content';
import {
	createTemplateMapping,
	editTemplateMapping,
	fetchCompanyTemplates,
} from '../../services/template-service';
import CustomSwitch from '../common/Switch';
import Tabs from '../common/Tabs';
import {
	addTemplateMapping,
	asyncCallDone,
	asyncCallStart,
	updateTemplateMappingInList,
} from '../../data/stores/templateStore';
import { TemplateMapping } from '../../data/models/template';
import useCompany from '../../hooks/useCompany';
import { showAlert } from '../../data/stores/alertStore';
import { AsyncStage } from '../../data/reduxCommon';

export default function ConfigureTemplateMappingModal() {
	const {
		openId,
		props: { templateMapping },
	} = useSelector((state: RootState) => state.modalState);
	const { stage } = useSelector((state: RootState) => state.templateState);

	const open = openId === 'addTemplateMappingModal';

	const dispatch = useDispatch<AppDispatch>();
	const { projectTypeOptions } = useCompany();
	const budgetOptions = budgetRangeOptions();

	type FormValues = {
		name: string;
		description: string;
		projectType: string;
		budgetRange: string;
		templates: any[];
	};
	const { handleSubmit, control, reset, formState, setValue } =
		useForm<FormValues>({
			defaultValues: {
				name: '',
				description: '',
				projectType: '',
				budgetRange: '',
				templates: [],
			},
			mode: 'onSubmit',
		});

	const { errors } = formState;

	useEffect(() => {
		const fetchTemplates = async () => {
			const templateIds = templateMapping?.templates.map((t) => t.id);
			const response = await fetchCompanyTemplates({ take: 1000 });
			const initialTemplates = response.list.map((t) => ({
				...t,
				selected: templateMapping ? templateIds.includes(t.id) : true,
			}));
			setValue('templates', initialTemplates);
		};
		if (open) {
			fetchTemplates();
		}
	}, [open, templateMapping, setValue]);

	useEffect(() => {
		if (templateMapping) {
			resetForm(templateMapping);
		}
	}, [templateMapping]);

	const resetForm = (templateMapping?: TemplateMapping) => {
		reset({
			name: templateMapping?.name || '',
			description: templateMapping?.description || '',
			projectType: templateMapping?.projectType || '',
			budgetRange: templateMapping?.budgetRange || '',
			templates: templateMapping?.templates || [],
		});
	};

	const handleClose = () => {
		dispatch(closeModal());
		resetForm();
	};

	const onSave = async (data) => {
		dispatch(asyncCallStart());
		try {
			const mappingData = {
				...data,
				templates: data.templates
					.filter((t) => t.selected)
					.map((t) => t.id),
			};
			if (templateMapping) {
				mappingData.id = templateMapping.id;
				const updatedTemplateMapping = await editTemplateMapping(
					mappingData
				);
				dispatch(updateTemplateMappingInList(updatedTemplateMapping));
				dispatch(
					showAlert({
						message: 'Template mapping updated successfully',
						severity: 'success',
					})
				);
			} else {
				const createdTemplateMapping = await createTemplateMapping(
					mappingData
				);
				dispatch(addTemplateMapping(createdTemplateMapping));
				dispatch(
					showAlert({
						message: 'Template mapping created successfully',
						severity: 'success',
					})
				);
			}
		} catch (error) {
			return console.error(error);
		} finally {
			dispatch(asyncCallDone());
		}
		handleClose();
	};

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			sx={{ overflow: 'scroll' }}
		>
			<Container>
				<ModalHeader>Configure Mapping</ModalHeader>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Controller
							name={'name'}
							control={control}
							rules={{ required: 'Name is required' }}
							render={({ field }) => (
								<Input
									{...field}
									label={'Mapping Name'}
									id="name"
									error={!!errors.name}
									helperText={errors.name?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<Controller
							name={'description'}
							control={control}
							rules={{ required: 'Description is required' }}
							render={({ field }) => (
								<Input
									{...field}
									label={'Mapping Description'}
									id="description"
									error={!!errors.description}
									helperText={errors.description?.message}
									multiline
									rows={3}
									maxRows={3}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<Controller
							name={'projectType'}
							control={control}
							rules={{
								required: 'Project Type is required',
							}}
							render={({ field }) => (
								<Autocomplete
									{...field}
									id={'type'}
									label={'Project Type'}
									options={projectTypeOptions}
									onChange={(e, value) => {
										field.onChange(value?.id || '');
									}}
									error={!!errors.projectType}
									helperText={errors.projectType?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<Controller
							name={'budgetRange'}
							control={control}
							rules={{
								required: 'Budget Range is required',
							}}
							render={({ field }) => (
								<Autocomplete
									{...field}
									id={'budgetRange'}
									label={'Budget Range'}
									options={budgetOptions}
									onChange={(e, value) => {
										field.onChange(value?.id || '');
									}}
									error={!!errors.budgetRange}
									helperText={errors.budgetRange?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<TemplateSelector control={control} />
					</Grid>
				</Grid>
				<SaveCancelContainer>
					<SaveCancelButtons
						handleSave={handleSubmit(onSave)}
						handleCancel={handleClose}
						saveText={!!templateMapping ? 'Update' : 'Create'}
						saveDisabled={stage === AsyncStage.Busy}
						cancelDisabled={stage === AsyncStage.Busy}
					/>
				</SaveCancelContainer>
			</Container>
		</Modal>
	);
}

const TemplateSelector = ({ control }) => {
	const { field } = useController({
		name: 'templates',
		control,
	});

	const [selectedPanel, setSelectedPanel] = useState('default');

	const handleOnChange = (id) => (e) => {
		const updatedTemplates = field.value.map((t) => {
			if (t.id === id) {
				return { ...t, selected: e.target.checked };
			}
			return t;
		});
		field.onChange(updatedTemplates);
	};

	const filteredTemplates = useMemo(() => {
		if (selectedPanel === 'default') {
			return field.value.filter((t) => t.default);
		} else {
			return field.value.filter((t) => !t.default);
		}
	}, [selectedPanel, field.value]);

	const panels = [
		{
			label: 'Default Templates',
			component: (
				<Templates
					templates={filteredTemplates}
					handleTemplateSelection={handleOnChange}
				/>
			),
			onclick: () => setSelectedPanel('default'),
			key: 'default',
		},
		{
			label: 'Custom',
			component: (
				<Templates
					templates={filteredTemplates}
					handleTemplateSelection={handleOnChange}
				/>
			),
			onclick: () => setSelectedPanel('custom'),
			key: 'custom',
		},
	];

	return (
		<TemplateSelectorContainer>
			<Tabs panels={panels} currentPath={selectedPanel} />
		</TemplateSelectorContainer>
	);
};

const Templates = (props) => {
	const { templates, handleTemplateSelection } = props;

	return (
		<div>
			{templates.map((t) => (
				<TemplateRow
					key={t.id}
					template={t}
					handleOnChange={handleTemplateSelection}
				/>
			))}
		</div>
	);
};

const TemplateRow = (props) => {
	const { template, handleOnChange } = props;

	return (
		<TemplateContainer>
			<div>{template.name}</div>
			<div>
				<CustomSwitch
					label={template.selected ? 'Enabled' : 'Disabled'}
					checked={template.selected}
					onChange={handleOnChange(template.id)}
				/>
			</div>
		</TemplateContainer>
	);
};

const TemplateSelectorContainer = styled.div`
	margin-top: 15px;
	display: flex;
	flex-direction: column;
`;

const TemplateContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Container = styled.div`
	min-width: 350px;
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;
